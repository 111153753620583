<template>
  <v-form ref="form"
        v-model="valid"
        lazy-validation
  >
    <v-container>
      <v-row class="mt-2">
        <v-col cols="4">
          <h2>協会編集</h2>
        </v-col>
        <v-col cols="3" class="left">
          <v-btn v-if="!isDelete && !isReadonly"
                class="btn_font primary" width="200" v-on:click="registData(mode)" :disabled="loading"
          >保存</v-btn>
          <v-btn v-else-if="!isReadonly"
                class="btn_font secondary" width="200" v-on:click="registData(mode)" :disabled="loading"
          >削除</v-btn>
        </v-col>
        <v-col cols="5" class="left">
          <v-btn v-if="isDelete || !isReadonly" class="btn_font primary ml-10" width="200" v-on:click="cancel()" :disabled="loading"
          >キャンセル</v-btn>
        </v-col>
      </v-row>

      <v-row class="mt-8">
        <v-col cols="2">
          <v-select
                label="日冷倉協/冷事協会"
                v-model="memberData.type"
                :items="associationType"
                dense
                :readonly="true"
                :loading="loading"
          ></v-select>
        </v-col>
        <v-col cols="2">
          <v-text-field label="会員ID"
                v-model="memberData.associationId"
                dense
                :readonly="mode != 'new' ? true : false"
                :loading="loading"
                :rules="[required]"
          ></v-text-field>
        </v-col>
        <v-col cols="2">
          <v-text-field label="登録番号"
                v-model="memberData.registNo"
                dense
                :readonly="mode != 'new' ? true : false"
                :loading="loading"
                :rules="[required]"
          ></v-text-field>
        </v-col>
        <v-col cols="2">
          <!-- <v-checkbox label="閉鎖廃止"
                v-model="memberData.closed"
                dense
                :readonly="isDelete"
                :loading="loading"
          ></v-checkbox> -->
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          <v-text-field label="協会名"
                v-model="memberData.associationName" dense
                :readonly="isDelete || isReadonly"
                :loading="loading" :rules="[required]"
                :maxlength="100"
                :counter="100"
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field label="協会名カナ"
                v-model="memberData.associationKana" dense
                :readonly="isDelete || isReadonly"
                :loading="loading"
                :maxlength="100"
                :counter="100"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="2">
          <v-text-field label="郵便番号"
                v-model="memberData.postCode" dense
                :readonly="isDelete || isReadonly"
                :loading="loading"
                :maxlength="10"
                :counter="10"
          ></v-text-field>
        </v-col>
        <v-col cols="2">
          <v-select label="都道府県"
                v-model="memberData.prefId"
                :items="prefList" dense
                :readonly="isDelete || isReadonly"
                :loading="loading"
          ></v-select>
        </v-col>
        <v-col cols="4">
          <v-text-field label="住所"
                v-model="memberData.address" dense
                :readonly="isDelete || isReadonly"
                :loading="loading"
                :maxlength="200"
                :counter="200"
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field label="ビル名"
                v-model="memberData.bldg" dense
                :readonly="isDelete || isReadonly"
                :loading="loading"
                :maxlength="100"
                :counter="100"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          <v-text-field label="URL"
                v-model="memberData.url" dense
                :readonly="isDelete || isReadonly"
                :loading="loading"
                :maxlength="100"
                :counter="100"
          ></v-text-field>
        </v-col>
        <v-col cols="2">
          <v-text-field label="電話番号"
                v-model="memberData.tel" dense
                :readonly="isDelete || isReadonly"
                :loading="loading"
                :maxlength="100"
                :counter="100"
          ></v-text-field>
        </v-col>
        <v-col cols="2">
          <v-text-field label="FAX番号"
                v-model="memberData.fax" dense
                :readonly="isDelete || isReadonly"
                :loading="loading"
                :maxlength="100"
                :counter="100"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
      </v-row>


      <v-row>
        <v-col cols="2">
          <v-text-field label="代表者役職"
                v-model="memberData.representativePost"
                dense :readonly="isDelete || isReadonly"
                :loading="loading"
                :maxlength="100"
                :counter="100"
          ></v-text-field>
        </v-col>
        <v-col cols="2">
          <v-text-field label="代表者名"
                  v-model="memberData.representativeName"
                  dense :readonly="isDelete || isReadonly"
                  :loading="loading"
                  :maxlength="100"
                  :counter="100"
          ></v-text-field>
          <v-text-field label="代表者名カナ"
                  v-model="memberData.representativeKana"
                  dense :readonly="isDelete || isReadonly"
                  :loading="loading"
                  :maxlength="100"
                  :counter="100"
          ></v-text-field>
        </v-col>
        <v-col cols="2">
          <v-text-field label="事務局長"
                  v-model="memberData.executiveDirectorName"
                  dense :readonly="isDelete || isReadonly"
                  :loading="loading"
                  :maxlength="100"
                  :counter="100"
          ></v-text-field>
          <v-text-field label="事務局長カナ"
                  v-model="memberData.executiveDirectorKana"
                  dense :readonly="isDelete || isReadonly"
                  :loading="loading"
                  :maxlength="100"
                  :counter="100"
          ></v-text-field>
        </v-col>
        <v-col cols="2">
          <v-text-field label="専務理事"
                  v-model="memberData.managingDirectorName"
                  dense :readonly="isDelete || isReadonly"
                  :loading="loading"
                  :maxlength="100"
                  :counter="100"
          ></v-text-field>
          <v-text-field label="専務理事カナ"
                  v-model="memberData.managingDirectorKana"
                  dense :readonly="isDelete || isReadonly"
                  :loading="loading"
                  :maxlength="100"
                  :counter="100"
          ></v-text-field>
        </v-col>
        <v-col cols="2">
          <v-text-field label="次長"
                  v-model="memberData.deputyManagerName"
                  dense :readonly="isDelete || isReadonly"
                  :loading="loading"
                  :maxlength="100"
                  :counter="100"
          ></v-text-field>
          <v-text-field label="次長カナ"
                  v-model="memberData.deputyManagerKana"
                  dense :readonly="isDelete || isReadonly"
                  :loading="loading"
                  :maxlength="100"
                  :counter="100"
          ></v-text-field>
        </v-col>
        <v-col cols="2">
          <v-text-field label="職員"
                  v-model="memberData.staffName"
                  dense :readonly="isDelete || isReadonly"
                  :loading="loading"
                  :maxlength="100"
                  :counter="100"
          ></v-text-field>
          <v-text-field label="職員カナ"
                  v-model="memberData.staffKana"
                  dense :readonly="isDelete || isReadonly"
                  :loading="loading"
                  :maxlength="100"
                  :counter="100"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <h4>会員数</h4>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="1">
          <v-text-field label="会員企業"
                class="right-input"
                v-model="memberData.numCompany"
                dense readonly :loading="loading"
          ></v-text-field>
        </v-col>
        <v-col cols="1">
          <v-text-field label="事業所会員Ⅰ"
                class="right-input"
                v-model="memberData.numIndustry1"
                dense readonly :loading="loading"
          ></v-text-field>
        </v-col>
        <v-col cols="1">
          <v-text-field label="事業所会員Ⅱ"
                class="right-input"
                v-model="memberData.numIndustry2"
                dense readonly :loading="loading"
          ></v-text-field>
        </v-col>
        <v-col cols="1">
          <v-text-field label="事業所会員Ⅲ"
                class="right-input"
                v-model="memberData.numIndustry3"
                dense readonly :loading="loading"
          ></v-text-field>
        </v-col>
        <v-col cols="1">
          <v-text-field label="賛助会員"
                class="right-input"
                v-model="memberData.numSupporter"
                dense readonly :loading="loading"
          ></v-text-field>
        </v-col>
        <v-col cols="1">
          <v-text-field label="日冷協会員"
                class="right-input"
                v-model="memberData.numBusiness"
                dense readonly :loading="loading"
          ></v-text-field>
        </v-col>
      </v-row>


      <v-row>
        <v-col>
          <h4>都道府県協会登録</h4>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="8">
          <v-data-table class="table-border" :hide-default-header="true" :hide-default-footer="true" dense>
            <template v-slot:body="{}">
              <thead>
                <tr>
                  <th width="40%" colspan=2 class="center">
                    所管容積：㎥（Ｆ級）
                  </th>
                  <th width="40%" colspan=2 class="center">
                    <span class="text-subtitle-1 font-weight-black">所管容積：㎥（Ｃ級）</span>
                  </th>
                  <th width="20%" class="center">
                    <span class="text-subtitle-1 font-weight-black">ＦＣ級合計</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>Ｆ１級 -20～30℃</th>
                  <td class="money-string">
                    {{dispNumber(memberData.f1Volume)}}
                  </td>
                  <th>Ｃ１級 -10～20℃</th>
                  <td class="money-string">
                    {{dispNumber(memberData.c1Volume)}}
                  </td>
                  <th></th>
                </tr>
                <tr>
                  <th>Ｆ２級 -30～40℃</th>
                  <td class="money-string">
                    {{dispNumber(memberData.f2Volume)}}
                  </td>
                  <th>Ｃ２級 -2～10℃</th>
                  <td class="money-string">
                    {{dispNumber(memberData.c2Volume)}}
                  </td>
                  <th></th>
                </tr>
                <tr>
                  <th>Ｆ３級 -40～50℃</th>
                  <td class="money-string">
                    {{dispNumber(memberData.f3Volume)}}
                  </td>
                  <th>Ｃ３級 +10～-2℃</th>
                  <td class="money-string">
                    {{dispNumber(memberData.c3Volume)}}
                  </td>
                  <th></th>
                </tr>
                <tr>
                  <th>Ｆ４級 -50℃～</th>
                  <td class="money-string">
                    {{dispNumber(memberData.f4Volume)}}
                  </td>
                  <th></th>
                  <th></th>
                  <th></th>
                </tr>
                <tr>
                  <th class="right">Ｆ級合計</th>
                  <td class="money-string">
                    {{dispNumber(memberData.fVolume)}}
                  </td>
                  <th class="right">Ｃ級合計</th>
                  <td class="money-string">
                    {{dispNumber(memberData.cVolume)}}
                  </td>
                  <td class="money-string">
                    {{dispNumber(memberData.volume)}}
                  </td>
                </tr>
                <tr>
                  <th>内 移動ラック：㎥</th>
                  <td class="money-string">
                    {{dispNumber(memberData.moveF)}}
                  </td>
                  <th>内 移動ラック：㎥</th>
                  <td class="money-string">
                    {{dispNumber(memberData.moveC)}}
                  </td>
                  <td class="money-string">
                    {{dispNumber(memberData.moveFc)}}
                  </td>
                </tr>
                <tr>
                  <th>内 自動倉庫：㎥</th>
                  <td class="money-string">
                    {{dispNumber(memberData.autoF)}}
                  </td>
                  <th>内 自動倉庫：㎥</th>
                  <td class="money-string">
                    {{dispNumber(memberData.autoC)}}
                  </td>
                  <td class="money-string">
                    {{dispNumber(memberData.autoFc)}}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="2">
          <v-text-field label="製氷能力"
              v-model="memberData.iceCapability"
              class="right-input"
              dense readonly
              :loading="loading"
          ></v-text-field>
        </v-col>
        <v-col cols="2">
          <v-text-field label="凍結能力"
              v-model="memberData.frozenCapability"
              class="right-input"
              dense readonly
              :loading="loading"
          ></v-text-field>
        </v-col>
      </v-row>

      <!-- ------------------------------------------------------- -->
      <div v-if="memberData.pref_total.length > 1" >
        <v-row class="mt-2">
          <v-col>
            <h4>県別</h4>
          </v-col>
        </v-row>

        <v-tabs v-model="tab">
          <v-tab v-for="item in memberData.pref_total" :key="item.prefId"
                :href="'#tab-' + item.prefId" >
            {{replacePrefIdToPrefName(item.prefId)}}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item v-for="item in memberData.pref_total"
                :key="item.prefId"
                :value="'tab-' + item.prefId"
                class="tab-area"
          >
            <v-row>
              <v-col>
                <h4>会員数</h4>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="1">
                <v-text-field label="会員企業"
                      class="right-input"
                      v-model="item.numCompany"
                      dense readonly :loading="loading"
                ></v-text-field>
              </v-col>
              <v-col cols="1">
                <v-text-field label="事業所会員Ⅰ"
                      class="right-input"
                      v-model="item.numIndustry1"
                      dense readonly :loading="loading"
                ></v-text-field>
              </v-col>
              <v-col cols="1">
                <v-text-field label="事業所会員Ⅱ"
                      class="right-input"
                      v-model="item.numIndustry2"
                      dense readonly :loading="loading"
                ></v-text-field>
              </v-col>
              <v-col cols="1">
                <v-text-field label="事業所会員Ⅲ"
                      class="right-input"
                      v-model="item.numIndustry3"
                      dense readonly :loading="loading"
                ></v-text-field>
              </v-col>
              <v-col cols="1">
                <v-text-field label="賛助会員"
                      class="right-input"
                      v-model="item.numSupporter"
                      dense readonly :loading="loading"
                ></v-text-field>
              </v-col>
              <v-col cols="1">
                <v-text-field label="日冷協会員"
                      class="right-input"
                      v-model="item.numBusiness"
                      dense readonly :loading="loading"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="8">
                <v-data-table class="table-border" :hide-default-header="true" :hide-default-footer="true" dense>
                  <template v-slot:body="{}">
                    <thead>
                      <tr>
                        <th width="40%" colspan=2 class="center">
                          所管容積：㎥（Ｆ級）
                        </th>
                        <th width="40%" colspan=2 class="center">
                          <span class="text-subtitle-1 font-weight-black">所管容積：㎥（Ｃ級）</span>
                        </th>
                        <th width="20%" class="center">
                          <span class="text-subtitle-1 font-weight-black">ＦＣ級合計</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th>Ｆ１級 -20～30℃</th>
                        <td class="money-string">
                          {{dispNumber(item.f1Volume)}}
                        </td>
                        <th>Ｃ１級 -10～20℃</th>
                        <td class="money-string">
                          {{dispNumber(item.c1Volume)}}
                        </td>
                        <th></th>
                      </tr>
                      <tr>
                        <th>Ｆ２級 -30～40℃</th>
                        <td class="money-string">
                          {{dispNumber(item.f2Volume)}}
                        </td>
                        <th>Ｃ２級 -2～10℃</th>
                        <td class="money-string">
                          {{dispNumber(item.c2Volume)}}
                        </td>
                        <th></th>
                      </tr>
                      <tr>
                        <th>Ｆ３級 -40～50℃</th>
                        <td class="money-string">
                          {{dispNumber(item.f3Volume)}}
                        </td>
                        <th>Ｃ３級 +10～-2℃</th>
                        <td class="money-string">
                          {{dispNumber(item.c3Volume)}}
                        </td>
                        <th></th>
                      </tr>
                      <tr>
                        <th>Ｆ４級 -50℃～</th>
                        <td class="money-string">
                          {{dispNumber(item.f4Volume)}}
                        </td>
                        <th></th>
                        <th></th>
                        <th></th>
                      </tr>
                      <tr>
                        <th class="right">Ｆ級合計</th>
                        <td class="money-string">
                          {{dispNumber(item.fVolume)}}
                        </td>
                        <th class="right">Ｃ級合計</th>
                        <td class="money-string">
                          {{dispNumber(item.cVolume)}}
                        </td>
                        <td class="money-string">
                          {{dispNumber(item.volume)}}
                        </td>
                      </tr>
                      <tr>
                        <th>内 移動ラック：㎥</th>
                        <td class="money-string">
                          {{dispNumber(item.moveF)}}
                        </td>
                        <th>内 移動ラック：㎥</th>
                        <td class="money-string">
                          {{dispNumber(item.moveC)}}
                        </td>
                        <td class="money-string">
                          {{dispNumber(item.moveFc)}}
                        </td>
                      </tr>
                      <tr>
                        <th>内 自動倉庫：㎥</th>
                        <td class="money-string">
                          {{dispNumber(item.autoF)}}
                        </td>
                        <th>内 自動倉庫：㎥</th>
                        <td class="money-string">
                          {{dispNumber(item.autoC)}}
                        </td>
                        <td class="money-string">
                          {{dispNumber(item.autoFc)}}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="2">
                <v-text-field label="製氷能力"
                    v-model="item.iceCapability"
                    class="right-input"
                    dense readonly
                    :loading="loading"
                ></v-text-field>
              </v-col>
              <v-col cols="2">
                <v-text-field label="凍結能力"
                    v-model="item.frozenCapability"
                    class="right-input"
                    dense readonly
                    :loading="loading"
                ></v-text-field>
              </v-col>
            </v-row>

          </v-tab-item>
        </v-tabs-items>
      </div>
      <!-- ------------------------------------------------------- -->

      <v-row>
        <v-col cols="12">
          <v-textarea label="備考" v-model="memberData.remarks" dense :readonly="isDelete" :loading="loading" rows="2" :maxlength="30000" :counter="30000"></v-textarea>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="2">
          <h4>ユーザ/連絡先</h4>
        </v-col>
        <v-col v-if="attribute == 2" class="pt-0" cols="10">
          <v-btn small class="primary" :height="40" :width="100"
                :disabled="loading" @click="dispUserList()"
          >ユーザ編集</v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pt-0" cols="12">
          <v-data-table
              :items="memberData.memberList"
              :headers="headers"
              hide-default-footer
              disable-pagination
              dense
              class="mt-5"
          >
            <template v-slot:item="{ item, index }">
              <tr v-show="!item.delete" :key="index">
                <td class="center">
                  {{ item.login_flag == '1' ? '○' : '' }}
                </td>
                <td>
                  {{ item.email }}
                </td>
                <td>
                  {{ item.name }}
                </td>
                <td>
                  {{ item.department }}
                </td>
                <td></td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
      </v-row>

      <!-- アクション -->
      <v-row class="mt-5 mb-5">
        <v-col cols="4">
        </v-col>
        <v-col cols="3" class="left">
          <v-btn v-if="!isDelete && !isReadonly"
                  class="btn_font primary" width="200" v-on:click="registData(mode)" :disabled="loading"
          >保存</v-btn>
          <v-btn v-else-if="!isReadonly"
                class="btn_font secondary" width="200" v-on:click="registData(mode)" :disabled="loading"
          >削除</v-btn>
        </v-col>
        <v-col cols="5" class="left">
          <v-btn v-if="isDelete || !isReadonly" class="btn_font primary ml-10" width="200" v-on:click="cancel()" :disabled="loading"
        >キャンセル</v-btn>
        </v-col>
      </v-row>
    </v-container>

  </v-form>
</template>

<script>
import Association from "../model/m_association";

import Mixin from "../mixins/const.js";

import Debug from "../Lib/Debug";
import Message from "../Lib/Message";

export default {

  mixins: [Mixin],

  data: () => ({
    // テーブルヘッダー
    headers: [
      { text: "ログイン", align: "left", sortable: true, value: "login_flag", width: "100px" },
      { text: "メールアドレス", align: "left", sortable: true, value: "to", width: "300px" },
      { text: "ユーザ名/氏名", align: "left", sortable: true, value: "name", width: "300px" },
      { text: "担当部門", align: "left", sortable: true, value: "department", width: "120px" },
      // { text: "実態調査", align: "center", sortable: true, value:"", width: "100px" },
      // { text: "電力・冷媒調査", align: "center", sortable: true, value:"", width: "100px" },
      // { text: "会費請求", align: "center", sortable: true, value:"", width: "100px" },
      // { text: "お知らせ", align: "center", sortable: true, value:"", width: "100px" },
      // { text: "宛先", align: "left", sortable: true, value: "to_address", width: "400px" },
      { text: "", align: "center", sortable: true },
    ],

    tab: null,


    // 編集ユーザデータ
    propMode: '',
    editItem: {},
    editItemIndex: null,

    valid : true,
    loading: false,

    mode: "",
    associationId: '',
    type: '',

    associationMypage: '',

    // 会員データテンプレート
    memberData: {
      sid: null,
      type: 0,
      associationId: "",
      registNo: '',
      associationName: "",
      associationKana: "",
      member: 1,
      closed: 0,
      numCompany: 0,
      numIndustry1: 0,
      numIndustry2: 0,
      numIndustry3: 0,
      numSupporter: 0,
      numBusiness: 0,
      prefId: null,
      postCode: "",
      address: "",
      bldg: "",
      tel: "",
      fax: "",
      representativeName: "",
      representativePost: "",
      representativeKana: "",
      executiveDirectorName: "",
      executiveDirectorKana: "",
      managingDirectorName: "",
      managingDirectorKana: "",
      deputyManagerName: "",
      deputyManagerKana: "",
      staffName: "",
      staffKana: "",
      f1Volume: null,
      f2Volume: null,
      f3Volume: null,
      f4Volume: null,
      fVolume: null,
      c1Volume: null,
      c2Volume: null,
      c3Volume: null,
      cVolume: null,
      volume: null,
      moveF: null,
      moveC: null,
      moveFc: null,
      autoF: null,
      autoC: null,
      autoFc: null,
      iceCapability: null,
      frozenCapability: null,
      contactDepartment: "",
      contactName: "",
      contactEmail: "",
      contactTo: "",
      url: "",
      remarks: "",
      cityDataSection: 0,
      pref_total: [],
    },

    // モーダル用
    modalOrg: "",         // 組織(会社 or 日冷倉協 or 冷事協)
    modalSelectedId: "",  // 現在表示している組織のID(company_id or association_id)
    modalList: [],        // モーダルで選択した組織

    // ログインユーザ権限
    attribute: 0,
  }),

  // ライフサイクルフック
  created: function() {
  },

  mounted: function() {
    // 検索条件
    const str = this.$store.getters.getStoreSearchCondition;

    // 協会 mypage の場合
    Debug.log(this.$route.path);
    Debug.log(this.$store.getters.getAttribute);
    this.attribute = this.$store.getters.getAttribute;
    if ((this.$route.path == '/association_edit_mypage')
          && (this.$store.getters.getAttribute) >= 1)  {

      // this.associationMypage = this.$store.getters.getMemberNo;
      this.associationMypage = this.$store.getters.getAssociation.association_id;
      Debug.log2("associationMypage->", this.associationMypage);

      // 0:日冷倉協 1:冷事協会
      this.type = 0;  // 冷事協会にログインユーザは存在しないので0固定
    } else {
      // 0:日冷倉協 1:冷事協会
      this.type = str.type; // 検索条件から設定
    }

    this.mode = this.$store.getters.getMode; // 新規 or 編集 or 削除をstoreから取得

    Debug.log2("Search Condition", str);
    Debug.log(this.mode);

    if ( (Object.keys(str).length > 0) && (this.mode == 'new')) {
      // 会社ID
      Debug.log(str.type);
      this.memberData.type = str.type;
    }

    this.loadList();
  },

  methods: {
    /** 表示更新 */
    async loadList() {
      Debug.log("function[loadList]");
      if (this.mode == "new") {
        return ;
      }

      this.loading = true;
      if (this.associationMypage != '') {
        this.associationId = this.associationMypage; // 協会のマイページ
      } else {
        this.associationId = this.$store.getters.getEditId; // 編集 or 削除する協会会員IDをstoreから取得
      }

      // DBから会員情報取得
      Association.getData(this.associationId, this.type)
        .then(res => {
          if(res && res.data) {
            this.memberData = res.data;

            Debug.log2("memberData", this.memberData);
          }
        })
        .catch((err) => {
          Message.err(err, "会員情報を取得できませんでした");
        })
        .finally(() => {
          this.loading = false;
        });
    },


    /** computedの自動計算値を更新 */
    updateValue() {
      Debug.log("function[updateValue]");
      this.memberData.fVolume = this.fVolumeCalc;
      this.memberData.cVolume = this.cVolumeCalc;
      this.memberData.volume = this.volumeCalc;
      this.memberData.moveFc = this.moveFcCalc;
      this.memberData.autoFc = this.autoFcCalc;
    },

    /** 保存 */
    async registData() {
      Debug.log("function[registData]");

      if (!this.$refs.form.validate()) {
        alert("入力に誤りがあります。内容をご確認ください。");
        return;
      }

      if (!confirm("保存します。よろしいですか？")) return;
      this.updateValue(); // computedの自動計算値を更新
      this.loading = true;

      // DBに会員情報登録 or 論理削除
      try {
        const res = await Association.setData(this.memberData, this.mode);

        // サーバーサイドのバリデーション
        const validationMsg = res.data;
        if (validationMsg) {
          let message = "";

          if (Array.isArray(validationMsg)) {
            validationMsg.forEach((m) => (message += m + "\n"));
          } else {
            message = validationMsg;
          }

          alert(message);
          this.loading = false;
          return;
        } else {
          alert("保存しました。");
          this.$router.back();
        }
      } catch (error) {
        Message.err(error, "保存できませんでした");
      }

      this.loading = false;
    },

    /** 削除 */
    async deleteData() {
      Debug.log("function[deleteData]");

      if (!confirm("削除します。よろしいですか？")) return;
      this.updateValue(); // computedの自動計算値を更新
      this.loading = true;

      // DB物理削除
      try {
        const res = await Association.deleteData(this.memberData.sid);

        // サーバーサイドのバリデーション
        const validationMsg = res.data;
        if (validationMsg) {
          let message = "";

          if (Array.isArray(validationMsg)) {
            validationMsg.forEach((m) => (message += m + "\n"));
          } else {
            message = validationMsg;
          }

          alert(message);
          this.loading = false;
          return;
        } else {
          alert("削除しました。");
          this.$router.back();
        }
      } catch (error) {
        Message.err(error, "削除できませんでした");
      }

      this.loading = false;
    },

    /** 取消 */
    cancel() {
      Debug.log("function[cancel]");

      if (!confirm("キャンセルします。よろしいですか？")) return;

      this.$router.back();
    },


    dispUserList() {
      // ユーザ一覧編集画面に遷移する
      const associationId = "?association_id=" + this.memberData.associationId;

      this.$router.push('/user_list' + associationId);
    },

    // ------------------------------------------------------------------------
    /** モーダル */
    showUserData(mode, item=null, index=null) {
      if(mode == 'new') {
        this.editItem = {
          login: false,
          name: '',
          email: '',
          password: '',
          department: '',
          to: '',
          toAddress: '',
          sendList: [],
          delete: false,
        };
      } else {
        this.editItem = JSON.parse(JSON.stringify(item));
      }
      this.propMode = mode;
      this.editItemIndex = index;
      this.modalList = this.officeMemberList;
      this.$modal.show("modal-user-dialog");
    },
    hideUserData: function () {
      Debug.log("function[hide]");

      this.$modal.hide("modal-user-dialog");
    },
    removeUserData() {
      Debug.log("function[remove]");

      this.$set(this.officeMemberList[this.editItemIndex], "delete", true);
    },
    /** 組織情報更新 */
    updateUserData(tmpStr) {
      Debug.log("function[updateUserData]");
      this.loading = true;

      console.log(tmpStr);
      this.officeMemberList = tmpStr;

      this.loading = false;
    },

    // 少数以下を四捨五入 + 3桁カンマ
    dispNumber(val) {
      return Math.round(val).toLocaleString();
    },

  },

  computed: {
    isDelete() {
      return this.mode == "delete";
    },
    isReadonly() {
      return this.associationMypage != '';
    },
  },

};
</script>

<style scoped>
.disablecolor {
  background: #bbbbbb;
}

.flex {
  display: flex;
  gap: 5%;
  align-items: center;
  justify-content: center;
}

.no-border-field::v-deep .v-input__control > .v-input__slot::before {
  border: none !important;
}

.money-string {
  text-align: right;
  font-size: 16px  !important;
}

.tab-area {
  background-color: #F0F0F0;
  padding-top: 10px;
  padding-left: 20px;
}

.table-border th {
  background-color: #EEE;
  font-size: 16px !important;
  font-weight: bold;
}

</style>
